import React from 'react';
import './About.css';
// import ME from '../../assets/me-about.jpg';
// import LX from '../../assets/IMGLX.jpeg';
import AGBA from '../../assets/mee.jpg';
import {CgAwards} from 'react-icons/cg';
import {FiUsers} from 'react-icons/fi';
import {VscFolder} from 'react-icons/vsc';

const About = () => {
  return ( 
    <section id='about'>
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className='.container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={AGBA} alt='About Image' />
          </div>
        </div>

        <div className='about__content'>
          <div className='about__cards'>
            <article className='about__card'>
              <CgAwards className='about__icon' />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>
            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Clients</h5>
              <small>0+ Clients WorldWide</small>
            </article>
            <article className='about__card'>
              <VscFolder className='about__icon' />
              <h5>Projects</h5>
              <small>43+ Completed</small>
            </article>
          </div>

          <p>
            LET'S WORK.
          </p>

          <a href='#contact' className='btn btn-primary'>Let's Talk</a>

        </div>
      </div>
    </section>
  )
}

export default About;

