import React from "react";
import "./Portfolio.css";
import PT1 from "../../assets/my-portfolio1.jpg";
import PT4 from "../../assets/my-portfolio4.jpg";
import PT5 from "../../assets/my-portfolio5.jpg";
import PT3 from "../../assets/baba.jpg";
import PT2 from "../../assets/my-portfolio2.jpg";
import PT6 from "../../assets/my-portfoliojob-2.jpeg";
import PT7 from "../../assets/less.jpg";
import RE8 from "../../assets/real-estate.jpg";
import CK9 from "../../assets/cocktail.jpg";
import CK10 from "../../assets/pizzagroove.jpg";
import CK11 from "../../assets/MERN.jpg";
import CK12 from "../../assets/car-hub.jpg";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Works</h5>
      <h2>Portfolio</h2>

      <div className="portfolio__container">



      <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={CK12} alt="" />
          </div>
          <h3>CAR-HUB</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/CAR-HUB"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://car-hub-dun-three.vercel.app/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

      <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={CK11} alt="" />
          </div>
          <h3>MERN-AUTH-APP</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/mern-auth"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://mern-auth-2b00.onrender.com/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>


      <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={CK10} alt="" />
          </div>
          <h3>GINGER'S PIZZA</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/getyourpizza"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://creative-strudel-b715ab.netlify.app/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT2} alt="" />
          </div>
          <h3>Netflix App Design</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/netflix-app-design"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://gingerlx-netflix-app-design.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={RE8} alt="" />
          </div>
          <h3>REAL ESTATE WEBSITE</h3>
          <div className="portfolio__item-cta">
            <a href="#" className="btn" target="__blank">
              Github
            </a>
            <a
              href="https://gingerlx-real-estate.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={CK9} alt="" />
          </div>
          <h3>COCKTAILS-SPECIAL</h3>
          <div className="portfolio__item-cta">
            <a href="#" className="btn" target="__blank">
              Github
            </a>
            <a
              href="https://your-favourite-cocktails.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT6} alt="" />
          </div>
          <h3>E-commerce Website</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/GINGER-CLOTHING---"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://beautiful-dieffenbachia-c9d7f2.netlify.app/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT3} alt="" />
          </div>
          <h3>Quiz-App</h3>
          <div className="portfolio__item-cta">
            <a href="https://github.com" className="btn" target="__blank">
              Github
            </a>
            <a
              href="https://millionaire-quiz-app.pages.dev//"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT4} alt="" />
          </div>
          <h3>Gym-Website</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/crypto-price-tracker"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://gingerlxfitclubwebsite.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT7} alt="" />
          </div>
          <h3>Color-pallette</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/color-palette"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href=" https://ginger-lx-color-palette.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT5} alt="" />
          </div>
          <h3>Note App</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/note-app"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://gingerlxnotesapp.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>

        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img src={PT1} alt="" />
          </div>
          <h3>Lorem Generator</h3>
          <div className="portfolio__item-cta">
            <a
              href="https://github.com/Davidglx/lorem-generator"
              className="btn"
              target="__blank"
            >
              Github
            </a>
            <a
              href="https://gingerlx-lorem-generator.pages.dev/"
              className="btn btn-primary"
              target="__blank"
            >
              Live Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;

// davidjohnson-portfolio